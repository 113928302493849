import { differenceInDays, startOfDay, startOfToday } from 'date-fns';
import * as t from 'io-ts';
import { logApiError } from 'lib/apiLogger';
import {
  deriveReturnableQuantities,
  getOrder,
} from 'lib/returns/returnUtilities';
import { validEmail } from 'lib/validation';
import { toCartItemMap } from 'models/cartItems/serializers';
import { CartItemModel } from 'models/cartItems/types';
import { toReturns } from 'models/returns/serializers';
import { ReturnModel } from 'models/returns/types';
import { NextApiHandler } from 'next';

interface RetrieveReturnableProductsFromOrderFailedResponse {
  success: false;
  message: string;
  redirectURL?: string;
}

interface RetrieveReturnableProductsFromOrderSuccessfulResponse {
  success: true;
  email: string;
  orderNumber: string;
  items: Record<string, CartItemModel>;
  returns: ReturnModel[];
}

export type RetrieveReturnableProductsFromOrderResponse =
  | RetrieveReturnableProductsFromOrderFailedResponse
  | RetrieveReturnableProductsFromOrderSuccessfulResponse;

export const RETURN_WINDOW_EXCEEDED_MESSAGE = 'RETURN_WINDOW_EXCEEDED';
export const GLOBAL_E_ORDER_MESSAGE = 'GLOBAL_E_ORDER';

// The return window in days, inclusive of the order date
const RETURN_WINDOW = 37;

const paramsSchema = t.interface({
  email: validEmail,
  locale: t.string,
  orderNumber: t.string,
});

export const retrieveReturnableProductsFromOrder: NextApiHandler<
  RetrieveReturnableProductsFromOrderResponse
> = async ({ method, body: params }, res) => {
  if (method !== 'POST') {
    res.setHeader('Allow', ['POST']);
    return res
      .status(405)
      .json({ success: false, message: `Method ${method} not allowed` });
  }

  if (!paramsSchema.is(params)) {
    return res.status(400).json({
      success: false,
      message: 'Please check that you have filled the fields out correctly',
    });
  }

  try {
    const order = await getOrder(params.orderNumber);

    // Bail if the input email and the order's email do not match
    if (order.customerEmail?.toLowerCase() !== params.email.toLowerCase()) {
      return res.status(400).json({
        success: false,
        message: "Your email address does not match this order's",
      });
    }

    if (
      order.custom?.fields.ge_id &&
      order.customerEmail &&
      (order.store?.key !== 'us-store' ||
        (order.store?.key === 'us-store' &&
          order.lineItems.every(
            ({ supplyChannel }) =>
              supplyChannel?.obj?.key !== 'us' &&
              supplyChannel?.obj?.key !== 'preorder_us'
          )))
    ) {
      // if its a globale order not from the US store,
      // or a globale order from the US store that does not use the US supply channels,
      // redirect to the globale returns portal
      return res.status(404).json({
        success: false,
        message: GLOBAL_E_ORDER_MESSAGE,
        redirectURL: `https://web.global-e.com/Returns/Portal/mZtl?orderId=${order.custom?.fields.ge_id}&email=${order.customerEmail}`,
      });
    }

    // Bail if the order is past the tolerated order recency, inclusive of the order date
    if (
      differenceInDays(startOfToday(), startOfDay(new Date(order.createdAt))) >
      RETURN_WINDOW
    ) {
      return res.status(404).json({
        success: false,
        message: RETURN_WINDOW_EXCEEDED_MESSAGE,
      });
    }

    // Get the allowed returns for this order
    const returnableProducts = deriveReturnableQuantities(order);

    return res.status(200).json({
      success: true,
      email: params.email,
      orderNumber: order.orderNumber || params.orderNumber,
      items: toCartItemMap({
        lineItems: Object.values(returnableProducts),
        locale: params.locale,
      }),
      returns: toReturns({ order: order, locale: params.locale }),
    });
  } catch (e) {
    logApiError(e);

    return res.status(404).json({
      success: false,
      message: 'Unfortunately, we were unable to retrieve your order',
    });
  }
};
