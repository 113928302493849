import commerceTools from 'lib/commerceTools';
import { getClientSideFeatureFlags } from 'lib/featureFlags';
import { getApiLocale } from 'lib/locale';
import nullable from 'lib/nullable';
import wait from 'lib/wait';
import { toCartItemMap } from 'models/cartItems/serializers';
import { deriveCartItemDisplayPrice } from 'models/cartItems/utilities';
import { toDiscountMap } from 'models/discounts/serializers';
import type { Currency } from 'models/locales/types';
import { shippingInfoToShippingMethod } from 'models/shippingMethods/serializers';
import type {
  DownstreamConfirmationState,
  ModelResponse,
  RetrieveConfirmationRequest,
} from 'store/confirmation/types';

const MAX_RETRIES = 15;
const INTERVAL = 3000;

const expand = [
  'discountCodes[*].discountCode',
  'discountCodes[*].discountCode.cartDiscounts[*]',
  'shippingInfo.shippingMethod[*]',
  'shippingInfo[*].shippingMethod[*]',
  'shippingInfo.discountedPrice.includedDiscounts[*].discount',
  'lineItems[*].discountedPricePerQuantity[*].discountedPrice.includedDiscounts[*].discount',
];

export const modelResponse: ModelResponse = ({ order, locale }) => {
  const { language } = getApiLocale(locale);

  const items = toCartItemMap({
    lineItems: order.lineItems,
    locale,
  });

  const freeItems = order.lineItems
    .filter(lineItem => lineItem.lineItemMode === 'GiftLineItem')
    .flatMap(lineItem => lineItem.discountedPricePerQuantity)
    .flatMap(item => item.discountedPrice.includedDiscounts);

  const { discountCodes, cartDiscounts } = toDiscountMap({
    discountCodes: order.discountCodes,
    cartDiscounts: [
      ...(order.shippingInfo?.discountedPrice?.includedDiscounts ?? []),
      ...freeItems,
    ],
    currency: order.totalPrice.currencyCode as Currency,
    language,
  });

  const response: DownstreamConfirmationState = {
    items,
    id: order.id,
    cartId: [order.cart?.id].join(''),
    country: [order.country].join(''),
    number: [order.orderNumber].join(''),
    email: [order.customerEmail].join(''),
    itemIds: Object.keys(items),
    total: nullable(order.totalPrice),
    displayPrice: deriveCartItemDisplayPrice(order.totalPrice),
    shippingAddress: nullable(order.shippingAddress),
    billingAddress: nullable(order.billingAddress),
    shippingMethod: shippingInfoToShippingMethod({
      shippingInfo: order.shippingInfo,
    }),
    discountCodes,
    cartDiscounts,
    taxPrice: {
      totalTax: deriveCartItemDisplayPrice(order.taxedPrice?.totalTax),
      totalGross: deriveCartItemDisplayPrice(order.taxedPrice?.totalGross),
    },
    taxInclusive:
      getClientSideFeatureFlags().isAvalaraEnabled === false
        ? true
        : order.taxedPrice?.totalGross
        ? order.taxedPrice.totalGross.centAmount === order.totalPrice.centAmount
        : true,
  };

  return response;
};

export const retrieveConfirmationRequest: RetrieveConfirmationRequest = async ({
  cartId,
  token,
  locale,
  retries = 0,
  maxRetries = MAX_RETRIES,
  interval = INTERVAL,
}) => {
  try {
    const { body } = await commerceTools
      .me()
      .orders()
      .get({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        queryArgs: {
          expand,
          where: `cart(id = "${cartId}")`,
          limit: 1,
        },
      })
      .execute();

    const [order] = body.results;

    if (order) {
      await fetch(`/services/graphene/confirm-order`, {
        method: 'POST',
        body: JSON.stringify({
          orderNumber: order.orderNumber,
          lineItems: order.lineItems,
          customerId: order.customerId,
          totalPrice: order.totalPrice,
          taxedPrice: order.taxedPrice,
          taxedShippingPrice: order.taxedShippingPrice,
        }),
      });

      return modelResponse({ order, locale });
    }

    if (retries === maxRetries) {
      throw new Error(
        `Cart ${cartId} timed out while trying to create an order.`
      );
    }

    await wait(interval);

    return retrieveConfirmationRequest({
      cartId,
      token,
      locale,
      maxRetries,
      retries: retries + 1,
    });
  } catch (e) {
    throw e;
  }
};
